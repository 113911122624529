<template>
  <div id="user-list">
    <v-row class="mb-5"></v-row>

    <!-- list filters -->
    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            Search &amp; Filter
          </v-col>

          <v-col
            align="end"
            class="pb-0"
          >
            <v-tooltip
              v-if="$can('create', 'Campaign')"
              bottom
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  fab
                  x-small
                  elevation="4"
                  color="primary"
                  :to="{ name: 'campaign-create' }"
                  v-on="on"
                >
                  <v-icon>
                    {{ icons.mdiPlus }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Add New Campaign</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card-title>

      <!-- table -->
      <vuex-table
        :headers="tableColumns"
        :items="campaigns"
        :meta.sync="meta"
        :search.sync="search"
        :options="options"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [5, 10, 25, 50, 100]
        }"
        @update:options="o => options = o"
      >
        <!-- name -->
        <template #[`item.campaignName`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              :color="item.thumbUrl ? '' : 'primary'"
              :class="item.thumbUrl ? '' : 'v-avatar-light-bg primary--text'"
              size="32"
            >
              <v-img
                v-if="item.thumbUrl"
                :src="item.thumbUrl"
              ></v-img>
              <span
                v-else
                class="font-weight-medium"
              >{{ avatarText(item.campaignName) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name : 'campaign-view', params : { id : item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.campaignName | textEllipsis(120) }}
              </router-link>
            </div>
          </div>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                class="me-1"
                v-bind="attrs"
                :to="{ name: 'campaign-view', params: { id: item.id } }"
                v-on="on"
              >
                <v-icon size="18">
                  {{ icons.mdiEyeOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>View Campaign</span>
          </v-tooltip>

          <v-tooltip
            v-if="$can('update', 'Campaign')"
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                class="me-1"
                v-bind="attrs"
                :to="{ name: 'campaign-edit', params: { id: item.id } }"
                v-on="on"
              >
                <v-icon size="18">
                  {{ icons.mdiPencil }}
                </v-icon>
              </v-btn>
            </template>
            <span>Edit Campaign</span>
          </v-tooltip>

          <v-tooltip
            v-if="$can('destroy', 'Campaign')"
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                color="error"
                v-bind="attrs"
                :disabled="deleting"
                :loading="isDialogOpen"
                @click="selectCampaignToDelete(item)"
                v-on="on"
              >
                <v-icon size="18">
                  {{ icons.mdiDeleteOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Delete Campaign</span>
          </v-tooltip>
        </template>
      </vuex-table>
    </v-card>

    <campaign-delete-dialog
      v-if="$can('destroy', 'Campaign')"
      :data="selectedCampaigns"
      :deleting="deleting"
      :is-dialog-open.sync="isDialogOpen"
    ></campaign-delete-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiDotsVertical, mdiEyeOutline, mdiPlus, mdiPencil, mdiDeleteOutline, mdiExportVariant } from '@mdi/js'

// eslint-disable-next-line object-curly-newline
import { ref, computed, getCurrentInstance, onMounted, onUnmounted } from '@vue/composition-api'

// sidebar
import { avatarText } from '@core/utils/filter'
import CampaignDeleteDialog from './CampaignDeleteDialog.vue'

import useCampaignList from './useCampaignList'

export default {
  components: {
    CampaignDeleteDialog,
  },
  setup() {
    const vm = getCurrentInstance().proxy

    const isDialogOpen = ref(false)

    const deleting = computed(() => vm.$store.getters['campaigns/deleting'])
    const selectedCampaigns = ref(null)
    const selectCampaignToDelete = c => {
      selectedCampaigns.value = c
      isDialogOpen.value = !!c
    }

    const {
      tableColumns,
      totalCampaignsListTable,
      loading,

      meta,
      filters,
      options,
      search,
      roleFilter,

      setOptions,
      setFilters,

      campaigns,
      campaignsListTable,
      campaignsTotalLocal,

      init,
      destroy,
    } = useCampaignList()

    onMounted(() => {
      init()
    })
    onUnmounted(() => {
      destroy()
    })

    return {
      campaignsListTable,
      tableColumns,
      totalCampaignsListTable,

      loading,
      campaignsTotalLocal,
      avatarText,

      setOptions,
      setFilters,

      campaigns,
      meta,
      filters,
      options,
      search,
      roleFilter,

      // icons
      icons: {
        mdiPlus,
        mdiPencil,
        mdiEyeOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiExportVariant,
      },

      isDialogOpen,

      deleting,
      selectedCampaigns,
      selectCampaignToDelete,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
